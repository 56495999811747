import React from "react"
import AllView from "../../views/all-view"
import { graphql } from 'gatsby'

const DesignAll = (props) => {
  const projects = props.data.design.nodes
  const imgData = props.data.images.nodes
  const location = props.location
  const category = props.data.category
  const categories = props.data.categories && props.data.categories.nodes

  return <AllView projects={projects} category={category} categories={categories} location={location} images={imgData} />
}

export default DesignAll


export const query = graphql`
  query($category: String) {
    design: allDesign(sort: {fields: order}){
      nodes {
        title
        slug
        category
        images {
          alt
          alt_over
        }
      }
    }
    images: allFile(filter: {sourceInstanceName: {eq: $category}, 
      extension: {regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(avif)/"}, 
      name: {regex: "/^([0-9 a-z A-Z]*)(_*)([^0-9]*)$/" }}) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            outputPixelDensities: [0.25, 0.5, 0.75, 1, 2]
            width: 300
          )
        }
        relativePath
      }
    }
    category: categories(_id: {eq: 5}) {
      _id
      slug
      name
      description
      type
      meta
      body
      files { 
        url
        text1
        text2
      }
    }
    categories: allCategories {
      nodes {
        _id
        slug
        name
        description
        type
        meta
        body
        files {
          url
          text1
          text2
        }
      }
    }
  }
`